import { Model, Attribute, HasMany, BelongsTo } from './decorators';
import { BaseApiModel } from './base';
import { ActiveStorageAttachmentType, RoleType, UserType } from '@parashift/shared/types';
import { GlobalRoles, Onboarding, OnboardingStatus, ResetOptions, TenantOnboarding } from './me.model';
import { Role } from './role.model';
import { ActiveStorageAttachment } from './active-storage-attachment.model';

export interface TokenCodeExchange {
  id: string;
  code_verifier: string;
}

export interface TokenResponse {
  token: string;
}

export interface UserPlainModel {
  id: string;
  created_at: string;
  current_password: string;
  disabled: boolean;
  email: string;
  global_roles: GlobalRoles[];
  language: string;
  last_used_tenant_id: number;
  name: string;
  password: string;
  settings: {
    onboarding?: Onboarding,
    reset?: ResetOptions;
    [key: string]: any
  };
  temp_roles: Role['plainModel'][];
  updated_at: string;
  confirmed_at: string;

  roles: (Role['plainModel'] | Partial<Role['plainModel']>)[];
  avatar_attachment: (ActiveStorageAttachment['plainModel'] | Partial<ActiveStorageAttachment['plainModel']>);
}

@Model({ type: UserType })
export class User extends BaseApiModel<UserPlainModel> {

  @Attribute({ readonly: true })
  created_at: string;

  @Attribute()
  current_password: string;

  @Attribute()
  disabled: boolean;

  @Attribute()
  email: string;

  @Attribute()
  global_roles: GlobalRoles[];

  @Attribute()
  language: string;

  @Attribute()
  last_used_tenant_id: number;

  @Attribute()
  name: string;

  @Attribute()
  password: string;

  @Attribute()
  settings: {
    onboarding?: Onboarding,
    reset?: ResetOptions;
    [key: string]: any
  };

  @Attribute({ readonly: true })
  updated_at: string;

  @Attribute()
  confirmed_at: string;

  // Includes / Relations

  @HasMany({ class: RoleType, sidepostable: true })
  roles: Role[];

  @BelongsTo({ class: ActiveStorageAttachmentType, sidepostable: true })
  avatar_attachment: (ActiveStorageAttachment | Partial<ActiveStorageAttachment>);

  temp_roles: Role['plainModel'][];

  getTempRoles() {
    if (!this.roles) {
      return [];
    }

    return this.roles.map(role => {
      return role.plainModel;
    });
  }

  get plainModel() {
    const model = this.toHash() as UserPlainModel;
    model.language = this.language || 'en';
    model.temp_roles = this.getTempRoles();
    return model;
  }

  set plainModel(model) {
    this.customUpdates = {
      temp_roles: setTempRoles,
    };

    this.setPlainModel(model);
  }

  getTenantOnboarding(tenant_id: number) {
    if (!this.settings) {
      this.settings = {};
    }
    if (!this.settings.onboarding) {
      this.settings.onboarding = {};
    }
    let onboarding = this.settings.onboarding[tenant_id];
    if (!onboarding) {
      this.settings.onboarding[tenant_id] = onboarding = { status: OnboardingStatus.company };
    }
    return onboarding;
  }

  setTenantOnboarding(tenant_id: number, onboarding: TenantOnboarding) {
    // only this way of immutable update results in .hasDirtyAttributes = true
    this.settings = {
      ...this.settings,
      onboarding: {
        ...this.settings.onboarding,
        [tenant_id]: onboarding
      }
    };
  }
}

function setTempRoles(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel']) {
  updatedPlainModel['temp_roles'].forEach((role: Role['plainModel'], index: number) => {
    existingModel['roles'][index].permissions = role.permissions;
  });
}
